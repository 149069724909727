$primary-green-color: #253933;
$primary-golden-color: #E49F2A;
$dark-golden:#ba7a0e;
$light-golden:#e49f2a8a;
$grey-color:#C1C1C1;
$dark-grey-color:#8D8D8D;
$box-shadow:#69696940;
$input-bg-color: #EBEDF3;

$primary-font: 'Nunito';
$secondary-font: 'Roboto';
$vector-top:url(../assets/images/vector-lines/line_vector_top.png);
$vector-left:url(../assets/images/vector-lines/line_vector_left.png);
$vector-right:url(../assets/images/vector-lines/line_vector_right.png);
$vector-3:url(../assets/images/vector-lines/line_vector_3_desktop.png)