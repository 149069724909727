@import "~bootstrap/scss/bootstrap";
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito&display=swap);
@import "variables";

//error red color  #dc3545
//notification page text color #434040
//black color #000
//white color #fff
//@include media-breakpoint-up(sm) {
html,body{
    font-family: $primary-font;
    font-size: 17px;
    font-style: normal;
    text-align: justify !important;
    // word-spacing:-2px;
}

*{
    scrollbar-width: thin !important;
    scrollbar-color: #1f5a30 transparent !important;
}

*::-webkit-scrollbar{
    width: 4px;
    height: 4px;
}
*::-webkit-scrollbar-thumb{
    background-color: #1f5a30;
    border-radius: 100vw
}
*::-webkit-scrollbar-track{
    background-color: transparent;
}






.fullVh{
    height: 100vh;
}

.headerLogo{
    width: 40%;
}
.mb-6 {
  margin-bottom: ($spacer * 3.5) !important;
}


.layout3section{
    margin-top: 4.7rem;
}
//==========highlight error=======//
.field-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}


.css-yk16xz-control, .css-1pahdxg-control {
    font-size: 1.2rem;
}

.is-invalid {
    .css-yk16xz-control {
        border-color: #dc3545;
        &:after {
            content: "!";
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 11px;
            height: 11px;
            border: 1px solid #dc3545;
            border-radius: 100%;
            font-size: 1rem;
            color: #dc3545;
            right: 10px;
        }
    }
}
//==========highlight error=======//

.top-bar {
    background-color: rgba(255, 255, 255, 1);
    height: 79px; 
    position: sticky;
    width: 100%;
    left: 0px;
    top: 0px;
    padding: 20px 25px;
    padding-left: 0;
    z-index: 10000;
    //background-color: yellow;
}

.top_nav_head_ham_menu{
    cursor: pointer;
    //margin: auto;
    //padding-right: 1rem;
}

.top_nav_head_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.top_nav_head_user {
    //margin-right: 10px;
    //display: flex;
    align-items: center;
}

.user-drop-down {
    position: relative;
    margin: 0 30px;
}

.user_name {
    cursor: pointer;
    padding-right: 1rem;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    color: #000;
//    overflow: scroll;
    max-height: 67px;
}
.header-loginLink{
    cursor: pointer;
    padding-right: 1rem;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    color:#000
}
.header-loginLink:hover{
    font-weight: bold;
    color:#000
}

.rotate-arrow::after {
    transform: rotate(180deg);
}

// .user_name::after {
//     content: "";
//     background-image: url(../assets/images/down-arrow.png);
//     background-size: cover;
//     width: 15px;
//     height: 13px;
//     pointer-events: none;
//     position: absolute;
//     top: 4px;
//     right: 0;
// }

.down-vector{
    content: "";
    background-image: url(../assets/images/down-arrow.png);
    background-size: cover;
    width: 15px;
    height: 13px;
    pointer-events: none;
    position: absolute;
    top: 4px;
    right: 0;  
}
/* my css for dropdown */

.nav-drop-down {
background-repeat:no-repeat;
position: absolute;
width: 30.22px;
height: 48px;
left: 1166px;
top: 16px;
}

.dropdown-item:hover
{
background: #E49F2A42;
border-left: 2px solid $primary-golden-color;
}

.dropdown-item:active
{
background: #E49F2A42;
border-left: 2px solid $primary-golden-color;
}

.dropdown-item:focus
{
background: #E49F2A42;
border-left: 2px solid $primary-golden-color;
}

.dropdown-menu{
    min-width: 9rem !important;
}

.willPageDropDown .dropdown-toggle{
    min-width: 7rem !important;
    background-color: $primary-golden-color !important;
    border-color:$primary-golden-color !important;
    color:#fff !important;
}

.willPageDropDown .dropdown-toggle:focus{
    box-shadow: $primary-golden-color !important;
}


.willPageDropDown .dropdown-menu{
    min-width: 7rem !important;
}

.dropdown-toggle::after {
    display: none;
}
/* my css for dropdown */

.drop-down-box li {
    list-style: none;
    padding: 10px 18px;
    background-color: #ffffff;
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.4;
    color: #000;
    cursor: pointer;
}

.drop-down-box li a {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.4;
    color: #000;
    text-decoration: none;
}

.drop-down-box li:focus, .drop-down-box li:hover {
    background-color: rgba(228, 159, 42, 0.26);
    border-left: solid 3px $primary-golden-color;
}


.drop-down-box {
    width: 156px;
    box-shadow: 5px 0 15px 0 $box-shadow;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px 0;
    position: absolute;
    left: -26px;
    margin-top: 15px;
    display: none;
}

.drop-down-box::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -13px;
    left: 59px;
}

.top_nav_head_user .button {
    margin-right: 10px;
}

button:hover{
    background-color: $dark-golden;
    color:#fff;
}

/* .top_nav_head_user select {
    border: none;
} */

// .body-cont {
//     margin-top: 67px;
// }

.body-cont .row {
    width: 100%;
}

//==============side bar style==================
.body_cont_side_bar {
    margin-top: 0;
    background-color: $primary-green-color;
}

.left-menu-container {
    margin-top: 2rem;
}

.left-menu-container ul {
    padding: 9%;
    list-style: none;
}

.left-menu-container ul li {
    text-decoration: none;
    padding: 11px;
    color: rgba(255, 255, 255, 1);
    letter-spacing: 0;
    font-size: 16px;
    line-height: 135%;
    margin-bottom: 42%;
    position: relative;
    height: 60px;
}

.customRowClass{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

.left-menu-container ul li a.disabled .left-menu-text{
 color:#c1b5b5;
 cursor: auto;
}

.left-menu-container ul li a{
    text-decoration: none;
    color: #ffffff;
}

.left-menu-container ul li a:not(.disabled):hover .left-menu-text,
.left-menu-container ul li a:not(.disabled):focus .left-menu-text {
    font-weight: 700;
}

.left-menu-container ul li a:not(.disabled):hover .left-menu-icon,
.left-menu-container ul li a:not(.disabled):focus .left-menu-icon{
   background-color: $primary-golden-color;
}

.left-menu-container ul li.active .left-menu-icon {
    background-color: $primary-golden-color;
}

.left-menu-container ul li.active .left-menu-text {
    font-weight: 700;
}

.left-menu-container ul li:last-child {
    margin-bottom: 0;
}

.left-menu-icon {
    border-radius: 500px;
    background-color: rgba(163.62499594688416, 163.62499594688416, 163.62499594688416, 1);
    width: 26px;
    height: 26px;
    position: absolute;
    left: 8px;
    padding: 2px 0px 0px 9px;
    z-index: 1;
}

.left-menu-container ul li::after {
    content: "";
    position: absolute;
    width: 2px;
    /* height: 140px; */
    height: 120px;
    background-color: $primary-golden-color;
    top: 37px;
    left: 20px;
}

.left-menu-container ul li:last-child::after {
    content: none;
}

.left-menu-text {
    display: inline-block;
    position: relative;
    left: 22%;
    padding-left: 0.5rem;
    padding-right: 1.5rem;
    word-break: break-word;
    text-align: start !important;
    word-spacing: normal !important;
}

.body_cont_main-layout {
    position: relative;
}

.main-layout {
    //padding: 1rem 2rem 2rem 2rem;
}

.main-layout .layout__headings {
    font-size: 22px;
    font-weight: 700;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: $primary-green-color;
    margin-top: 2rem;
}

.page-sub-heading{
    color: #6F6C6C;
    font-size: 18px;
    font-weight: 100;
}

.main-layout-cont {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.main-layout-cont form label {
    font-weight: 400;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #000;
    display: block;
    //@debug: capitalize;
}

.main-layout-cont form label img {
    height: 20px;
    filter: grayscale(1);
}

.main-layout-cont form input {
    height: 50px;
    padding: 0 20px;
    border-radius: 10px;
    //border: solid 1px #606060;
}

.form-control{
font-size: 17px !important;
}

.form-control:focus{
    box-shadow: none !important;
    background-color: #fff !important;
}

.main-layout-cont form input::placeholder {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #adabab;
}


.main-layout-cont form .form-check-label{
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(37, 57, 51, 0.44);
    display: inline-flex;
}


.register-checkbox{
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(37, 57, 51, 0.44);
    display: inline-flex;
}

.button {
    width: 11rem;
    padding: 11px 14px 11px;
    border: none;
    background: $primary-golden-color;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    z-index:1;
    outline:none !important;
}


.button:disabled {
    width: 9.25rem;
    padding: 12px 14px 11px;
    border: none;
    background: $dark-grey-color;
    border-radius: 10px;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    z-index:1;
}

.fixed-continue-btn {
    position: fixed;
    bottom: 31px;
    right: 26px;
}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 39px;
    background-color: #ffffff;
    padding: 20px 30px;
    z-index: 9;
}


/* footer .row {
    margin-right: 0;
} */

footer .footer-contact img {
    width: 20px;
    transform: rotate(-88deg);
}

footer .footer-contact span {

    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #16110d;
    border-bottom: solid 1px #a7a5a4;
}

.footer-note {
    margin-bottom: 0;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #373636;
}



//=======account setting page==========

.subheader { 
	box-shadow:8px 8px 34px $box-shadow;
	background-color:rgba(255, 255, 255, 0.11999999731779099);
	border-radius:10px;
    display: flex;
    padding: 1rem;
}

.subheader .profile-pic { 
	box-shadow:5px 2px 17px $box-shadow;
	border-radius:500px;
	width:3rem;
	height:3rem;
}

.subheader .username { 
    margin-top: 13px;
	font-size:20px;
	letter-spacing:0;
	font-weight: 700;
	line-height: 140%;
}


// *{
//     box-sizing: border-box;
//     margin: 0;
//     padding: 0;
// }

.logout-button {
    float: right;
    margin-top: 13px;
}

.box-shadow{
    box-shadow:8px 8px 34px $box-shadow;
	background-color:rgba(255, 255, 255, 0.11999999731779099);
}

.border-10{
	border-radius:10px;
}

.body_cont_main-layout-2 {
	position:relative;
    padding-bottom: 2.5rem;
}


.acc-page-menu-divider {
	border-right: 2px solid #E6E6E6;
}

.acc-page-menu {
	font-weight: 400;
	font-size: 20px;
	line-height: 140%;
	/* identical to box height, or 28px */
	display: flex;
	align-items: center;
	color: #838181;
	margin-bottom: 15px;
}

.acc-page-menu:hover {
	font-weight: 400;
	font-size: 20px;
	line-height: 140%;
	/* identical to box height, or 28px */
	display: flex;
	align-items: center;
	color: #838181;
}

.acc-page-menu:focus {
	font-weight: 700;
	font-size: 20px;
	line-height: 140%;
	/* identical to box height, or 28px */
	display: flex;
	align-items: center;
	color: $primary-green-color;
}

.acc-page-menu.active{
	font-weight: 700;
	font-size: 20px;
	line-height: 140%;
	/* identical to box height, or 28px */
	display: flex;
	align-items: center;
	color: $primary-green-color;
}

.acc-page-heading {
	//margin-bottom:22px;
	font-weight: 700;
	font-size: 22px;
	line-height: 140%;
	display: flex;
	align-items: center;
	color: $primary-green-color;
}

.page-heading .left-arrow-icon{
	color: $primary-golden-color;
    margin-right: 0.5rem;
    cursor: pointer;
}


//=================asset page==============

.selectAssetContainer{
    display: flex;
    flex-wrap: wrap;
}
.asset-box {
	text-decoration: none;
    width: 7rem;
    height: 7rem;
	border: 1px solid rgba(196,196,196,1);
    border-radius: 10px;
    margin-bottom: 1.5rem;
}

// .asset-box :not(:last-child){

// }

.asset-box .card-body{
    padding:0.5rem 0.5rem;
}

.asset-box .asset-icon{
	width: 36px;
	height: 35px;
	background-repeat: no-repeat;
	background-size: cover;
	opacity: 1;
	margin-top: 1rem;
	overflow: hidden;
    -webkit-filter: contrast(500%);
}

.asset-box .asset-name {
    text-decoration: none;
	color: rgba(0,0,0,1);
	margin-top: 0.5rem;

	font-weight: normal;
	font-size: 14px;
    line-height: 0.9rem;
    // overflow-y: scroll;
    // height: 2rem;
}

//===on hover

.asset-box:hover, .asset-box:focus {
    background-color: $primary-golden-color;
    box-shadow:9px 7px 23px $box-shadow;
}
.asset-box a {
    text-decoration: none;
    height:7rem;
}
.asset-box:hover .asset-icon, .asset-box:focus .asset-icon{
-webkit-filter: invert(1) contrast(500%);
}

.asset-box:hover .asset-name {
	color: rgba(255,255,255,255);
    font-weight: bold;
}

//==on hover end

.asset-box:focus .asset-name {
	color: rgba(255,255,255,255);
	font-weight: 600;
}

//=============payment success ===========

.pay-success-icon{
    width: 1.5rem;
    margin-top: -0.2rem;
    height: 1.5rem;
}

.download-button{
    width: 9rem;
    padding: 12px 14px 11px;
    border: none;
    border: 1px solid $primary-golden-color;
    color: $primary-golden-color;
    border-radius: 10px;

    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    background-color: #ffffff;
    z-index: 1;
}

.download-button img{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
}
.download-button:hover img{
 -webkit-filter: brightness(120%) sepia(0%) hue-rotate(0deg) saturate(0%) contrast(2.8);
    color:#fff;
}
.pay-success-ol{
    line-height:37px;
}

//==========drop-box-modal============
// .popup-style-1 .form-button{
//     width: 130px;
//     height: 47px; 
//     padding: 12px 14px 11px;
//     border: none;
//     background: $primary-golden-color;
//     border-radius: 10px;
//     color: white;
//     font-size: 18px;
//     font-weight: bold;
//     font-stretch: normal;
//   
//     line-height: 1.4;
//     letter-spacing: normal;
//     color: #ffffff;
//     z-index:1;
//     float: right;
// }

.form-button{
    float: right;
}

.dropbox-section{
    margin-bottom: 1rem;
}

.modalCloseIcon {
    color: $primary-golden-color;
    right: -0.6rem;
    top: -0.7rem;
    position: absolute;
    background-color: white;
    border-radius: 1rem;
    height: 1.5rem;
    cursor: pointer;
    z-index:1;
}

.dropbox-container{
    background: white !important;
    border: 1px solid $primary-green-color !important;
    border-style: dashed !important;
}
.dropbox-container .tagline{
    font-style: Mixed;
    font-size: 20px;
    line-height: 28px;
    font-weight: 100;
    text-align: center;
    display: block;
}
.dropbox-container .tagline small{
    font-style: Mixed;
    font-size: 15px;
    line-height: 28px;
    font-weight: 100;
    text-align: center;
    display: block;
}

.drag-drop-browse-link{
    color: $primary-golden-color;
}
.drag-drop-browse-link:hover{
    color: $primary-golden-color;
}

.uploadFileList{    
    font-weight: Bold;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    margin-top:1rem;
    margin-bottom: 1rem;
}

.uploadFileList span {
    font-size: 17px !important;
}

.uploadFileList img{
    width: 4rem;
    height: 4rem;
}

.progress{
    height:0.8rem
}

.uploadFileList .progress {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
}

.uploadFileList .progress-bar {
    background-color: $primary-golden-color !important;
}
//===add asset nominee===============
.add-nominee-btn{
    margin-top: 1.70rem;
    padding: 12px 14px 11px;
    border: none;
    background: $primary-golden-color;
    border-radius: 10px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    z-index:1;
}

.dltIcon{
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}
.editIcon{
    width: 1.2rem;
    height: 1.5rem;
    cursor: pointer;
    margin-right: 1rem;
}
.copyIcon{
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    margin-right: 1rem;
    color: $primary-golden-color;
    margin-top: 0;
    margin-right: 0;
    margin-left: 10px;
}

.btn-check:focus + .btn-primary, .btn-primary:focus{
    box-shadow: 0 0 0 0.25rem $light-golden !important;   
}

.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem $light-golden !important;
}

.nomineeTable tbody{
    border-top: 2px solid #7C7C7C !important;
}
.nomineeTable tfoot{
    border-top: 1px solid #848484 !important;
    color: #000;
}
.nomineeTable tfoot.error{
    border-top: 1px solid #848484 !important;
    color: #dc3545;
}
.nomineeTable td {
    border:0
}

//========Error modal================
.ErrorModal{
    border-radius: 1rem;
}
.ErrorModal .modal-body{
    background-color:#940202 ;
    color: #ffffff;
    border-radius: 8px !important;
    cursor: pointer;
}

.ErrorModal a{
    text-decoration: none;
    color: #ffffff;
}
.ErrorModal .modal-content{
   border-radius: 10px !important;
}
.ErrorModal .errorText{
    font-weight: 100;
    font-size: 16px;
}


//=============success modal===================
.successModal{
    border-radius: 1rem;
}
.successModal .modal-body{
    background-color:$primary-green-color ;
    color: #ffffff;
    border-radius: 10px !important;
    cursor: pointer;
}
.successModal .modal-content{
   border-radius: 12px !important;
}
.successModal .text{
    font-weight: 100;
    font-size: 16px;
    line-height: 1.3rem;
    padding: 0.8rem 0.5rem 0 3rem;
}


.editAssetLink{
    text-decoration: none;
    color: $primary-golden-color;
    border-bottom: 1px solid $primary-golden-color;
    font-weight: bold;
    margin-left: 1rem;
}

.editAssetLink:hover{
    text-decoration: none;
    color: $primary-golden-color;
    border-bottom: 1px solid $primary-golden-color;
    font-weight: bold;
    margin-left: 1rem;
}
//===asset summary page======

.assetSummaryTable{
    overflow-x: scroll;
}
.assetSummaryTable tbody{
    //border-top: 2px solid $primary-golden-color !important;
    border-top: none !important;
    line-height: 2rem;
}

.assetSummaryTable thead tr td {
    border-bottom: 2px solid $primary-golden-color !important;

}

.assetDetailCol{
    color: #7B7979;
    word-break: break-word;
    font-size: 14px;
    line-height: 0.90rem;
}

.addAssetBtn {
    text-decoration: none;
    padding: 0.5rem 0.9rem 0.5rem 0.9rem;
    float: right;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    background: $primary-golden-color;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    text-align: center;
    line-height: 1.7;
}

.addAssetBtn:hover {
    border: 1px solid $primary-golden-color;
    background: #fff;
    color: $primary-golden-color;
}

.btn-2{
    text-decoration: none;
    padding: 0.5rem 0.9rem 0.5rem 0.9rem;
    border: 1px solid $primary-golden-color;
    background: #ffffff;
    border-radius: 10px;
    color: $primary-golden-color; 
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
}

.btn-2:hover,.btn-2:focus{
    border: 1px solid #fff;
    background: $primary-golden-color;
    color: #fff; 
}

a.button {
    text-decoration: none;
    text-align: center;
}
a:hover.button {
    text-decoration: none;
    text-align: center;
    color: #fff;
    background-color: $dark-golden;
}



//======modal setting====
.modal-backdrop.show {
    opacity: 0.5;
    z-index: 10000;
}
.modal {
    z-index: 10000;
}

//===========layout 2 =========

.body-cont-2{
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 1rem;
   // border: 1px solid;
}

// .modal-scrollbar-measure 
// {
// overflow: hidden !important; 
// }

// .modal-body{
//   height: 25rem;
//   overflow-y: auto;
// }

//==========current row page ====
.rowStyle{
    box-shadow:5px 2px 17px $box-shadow;
    border-radius: 6px;
    //font-size: 16px;
}

.rowStyle a{
text-decoration: none;
color: #000;
cursor: pointer;
}
.readMore a {
    color: $primary-golden-color;
    font-weight: 700;
}

// .editWillCol img{
// width: 18% !important;
// height: 70% !important;
// }


.file-icon {
    color: $primary-golden-color;
    margin-right: 10px;
}

//========payment plan page==========

.payplan-box{
    text-decoration: none;
	border: 1px solid rgba(196,196,196,1);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
    font-size: 14px;
    cursor: pointer;
}
.payplan-box .plan-head{
    font-size: 18px;
}

.payplan-box ul li {
    margin-top: 0.5rem;
    line-height: 1rem;
}

.payplan-box:hover,.payplan-box.selected {
    background-color: $primary-golden-color;
    color: #fff;
    box-shadow:9px 7px 23px $box-shadow;
}
.payplan-box:hover .plan-head, .payplan-box.selected .plan-head{
    font-weight: bold;
}

.couponBox{
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid rgba(196,196,196,1);
    border-radius: 10px;

}

.payPlanContainer{
    display: grid;
    gap: 1.6rem;
    grid-template-columns: auto auto auto;
}

//==================notification===========

.notification-page-row {
    color: #434040;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 2rem;
}

.yellowColor {
    color: $primary-golden-color;
}

.greyColor{
    color:$grey-color;
}

.small{
    font-size:14px;
}

.progressBar{
    border-bottom: 0.5px solid #000;
    height: 0;
}

.pointer{
    position: relative;
    color:#9A9A9A; 
}

.pointer-text{
    position: absolute;
    top: 1rem;
    font-size: 13px;
    font-weight: bold;
    margin-top: 1rem;
}
.pointer-text.start{
    left: -1rem;
}

.pointer-text.center1{
    left: -35px;
}

.pointer-text.center2{
    right: -6px;
}

//===========================
.pointer.active .pointer-text,
.pointer.active .pointer-text.start,
.pointer-text.center1.active,
.pointer-text.center2.active{
    color:#000;
}

.pointer-icon{
    position: absolute;
    content: "";
    height: 35px;
    width: 35px;
    bottom: 0;
    right:0;
    top:1px;
    background-color: #9A9A9A;
    border-radius: 50%;
}

.pointer-icon.start{   
    left: -23px;
}

.pointer-icon.end{   
    right:-1px;
    top:0;
}

.pointer.active .pointer-icon,
.pointer-icon.start.active,
.pointer-icon.end.active
{
    background-color: $primary-golden-color;
}

.font-16{
    font-size: 0.8rem;
}

.font-14{
    font-size: 0.7rem;
}

.font-18{
    font-size: 0.9rem;
}


.login-form input, .login-form input:focus {
    border-bottom: 1px solid #A3A2A2;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    font-family: $secondary-font;
    font-size: 15px;
    line-height: 21px;
    vertical-align: center;
    margin-top: 2rem;
    padding-left: 0;
    box-shadow: none;
}

.login-form input::placeholder {
    font-family: $secondary-font;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #928E8E;
}

.login-top-bar {
    background-color: rgba(255, 255, 255, 1);
    height: 79px; 
    width: 100%;
    left: 0px;
    top: 0px;
    padding: 20px 25px;
    z-index: 10000;
}

.login-img{
    width: 95%;
    margin-left: -4rem;
    margin-bottom: 1rem;
}

.login-title{
    color: #1F5A30;
    margin-bottom:1rem;
    font-family: $secondary-font;
    font-size: 25px;
    font-weight: bold;
}

.reg-now{
    font-family: $secondary-font;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #6C6B6B;
}

.reg-now-link{
    font-family: $secondary-font;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
}

.reg-now-link:hover{
    font-weight: bold;
    color: $primary-golden-color;
}

form .form-check-label{
    font-size: 17px;
    font-weight: 400;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(37, 57, 51, 0.44);
    display: inline-flex;
}


.form-check-input{
    width: 1rem !important;
    height: 1rem !important;
    padding: 0 !important;
    border:  1px solid $primary-golden-color !important;
    margin-right: 0.5rem !important;
    border-radius: 0.25em !important;
}
.form-check-input:checked{
    background-color: $primary-golden-color;
    border: none;
}
.form-check-input:focus{
    box-shadow: none;
}

//=============nominee name list==============
.name-list span{
border: 1px solid $primary-golden-color;
border-radius: 1rem;
margin-right: 0.3rem;
padding-right: 0.2rem;
padding-left: 0.2rem;
font-size: 12px;
display: inline-block;
cursor: pointer;
text-transform: capitalize;
}

.name-list span .cancel-icon{
color:$primary-golden-color;
margin-left: 0.3rem;
margin-right: 0.1rem;
cursor: pointer;
}

//============radio button style===========
[data-palmerhq-radio] {
    border: 0 !important;
    cursor: pointer !important;
    margin: 0.5rem 0 !important;
    font-size: 18px;
}
[data-palmerhq-radio]:hover {
    background-color:transparent !important;
}
[data-palmerhq-radio]+[data-palmerhq-radio] {
    margin-left: 0 !important;
}

[data-palmerhq-radio]::before {
    width: 18px !important;
    height: 18px !important;
    border: 0 !important;
    background-image: none !important;
    background-color: #EBEDF3 !important;
}
[data-palmerhq-radio][aria-checked="true"]::before {
    background-image: none !important;
    background-color: $primary-golden-color !important;
}
[data-palmerhq-radio][aria-checked="mixed"]:active::before, 
[data-palmerhq-radio][aria-checked="true"]:active::before {
    background-image: none !important;
    background-color: $primary-golden-color !important;
}
[data-palmerhq-radio][aria-checked="true"]::after {
    border: 4px solid #fff !important;
    transform: translate(15%, -50%) !important;
}

//===========executor popup 

//========executor popup====//
.executorPopup p{
    color: #6F6C6C;
    line-height: 1.3rem;
    margin-top: 1rem;
}
.executorPopup .modal-body {
    font-size: 14px;
}

.is-invalid {
    .css-yk16xz-control {
        border-color: #dc3545;
        &:after {
            content: "!";
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 11px;
            height: 11px;
            border: 1px solid #dc3545;
            border-radius: 100%;
            font-size: 1rem;
            color: #dc3545;
            right: 10px;
        }
    }
}
// .executorPopup .executor-que{
// font-size: ;
// }

//==============

.css-b62m3t-container{
height: 50px;
}
.css-319lph-ValueContainer{
display: flex !important;
}
.css-1s2u09g-control, .css-319lph-ValueContainer, .css-6j8wv5-Input {
    height: 50px;
}
.css-1s2u09g-control{
    border-radius: 10px !important;
    border-color:  #ced4da !important;
}
.css-1s2u09g-control:hover{
    border-radius: 10px !important;
}

.css-qc6sy-singleValue{
font-size: 17px !important;
//padding-bottom: 5px !important;
}

// .css-1s2u09g-control:focus  .css-319lph-ValueContainer .css-qc6sy-singleValue{
// padding-bottom: 0 !important;
// }

//================Home page design=========

.home-page section{
    padding: 0 3rem 0 3rem;
}

.section-heading{
    color: $primary-green-color;
    font-weight: bold;
}
.section-one{
    background-color: $primary-green-color;
    margin-bottom: 5rem;
}
.section-one::before{
    content:"";
    position: absolute;
    //background-image: $vector-top;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 117%;
    width: 70%;
    top: -28rem;
    left: 0;
}

.section-one .leftSection{
    padding-top: 6rem;
}

.leftSection p {
    color: #DCDBDB;
    font-size: 23px;
    font-weight: 400;
}

.why-trust-head{
    color: $primary-golden-color;
    font-weight: bold;
    line-height: 3rem;
    margin-bottom: 2rem;
}

.section-one .card{
    border-radius: 1rem;
    margin-bottom: 2rem;
}

.section-one .card-body{
    display: flex;
}

.section-one .card-body p{
    font-size: 14px;
    margin: 0;
}

.card-body {
    padding-bottom: 65px;
}

.section-one .rightSection .serialNo{
    background: #F2B84B;
    border-radius: 50%;
    width: 5rem;
    padding: 1.1rem 1.3rem 1.2rem 1.3rem;
    text-align: center;
    height: 3.5rem;
    color: #fff;
    margin: auto;
    float: left;
    margin-left: -3rem;
}


.card-body-style{
    padding-bottom: 51px;
}

.card-body-style-section{
    padding-bottom: 0px;
}

.row-style{
    margin-bottom: 60px;
}


.mobile-view-space{
    margin-bottom: 30px;
}
//======section one end

.section-two{
    margin-bottom: 5rem;
}

.section-two::after{
    content:"";
    position: absolute;
    background-image: $vector-right;
    background-repeat: no-repeat;
    background-size: 100%;
    top: 0;
    right: 0;
    height: 120%;
    width: 17%;
    z-index: -1;
}
.section-two .card {
 border-radius: 0.8rem;
 min-height: 13rem ;
 font-size: 16px;
}

.border-line{
    background: $primary-golden-color;
    width: 5rem;
    padding: 5px;
    border-radius: 1rem;
    margin-left: -1.8rem;
}

.section-two .card:hover,.section-two .card:focus{
    background: $primary-green-color;
    box-shadow:9px 7px 23px $box-shadow;
    color: #fff;
}

.section-two .serialNo{
    color: $primary-green-color;
    font-weight: bold;
    line-height: 2.3rem;
    font-size: 1.3rem;
    padding-right: 1rem;

}
.section-two .card:hover .serialNo{
    color: #F2B84B;
}

//======section two end
.section-three{
    padding:4rem 3rem 7rem 3rem;
    background:rgba(255, 207, 126, 0.21);
    padding-top:5rem;
    padding-bottom:10rem;
    //clip-path: polygon(0% 0%, 100% 21%, 100% 100%, 0% 90%);

    --path: 0% 0%, calc(100% + 76%) 21%, 100% 100%, 0% 90%;
    clip-path: polygon(var(--path));   
}

.four-five-background::after{
    transform: scaleX(-1);
	filter: FlipH;
	-ms-filter: "FlipH";
    content:"";
    position: absolute;
    //background-image: $vector-right;
    background-repeat: no-repeat;
    background-size: 100%;
    top: 0;
    left: 0;
    height: 120%;
    width: 17%;
    z-index: -1;
}

.section-help::before{
    content:"";
    position: absolute;
    //background-image: $vector-3;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: -4.7rem;
    right: 0;
    height: 81%;
    width: 63%;
    z-index: 0;
}

.section-blog .carousel-inner::before
{
    content:"";
    position: absolute;
    //background-image: $vector-3;
    background-repeat: no-repeat;
    background-size: 100%;
    right: 0;
    height: 100%;
    width: 35%;
    margin-top: -2rem;
}

.section-three .section-heading{
    padding-top: 6rem;
}

.section-three .nav-item{
    padding: 2rem 3rem 2rem 0rem;
}
.section-three .nav-pills .nav-link{
    font-size: 1.5rem;
    color: $primary-green-color;
    border: 1px solid #DADADA;
    border-radius: 0.8rem;
    padding:1rem 3.8rem 1rem 3.8rem;
}

.section-three .content{
    padding-bottom: 6rem;
}

// .section-three .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
.section-three .nav-pills .nav-link.active, .section-three .nav-pills .nav-link:focus,.section-three .nav-pills .nav-link:hover{
    background: $primary-golden-color;
    font-weight: bold;
    font-size: 1.5rem;
    color: #fff;
}

.section-three .card {
 background:rgba(255, 245, 228, 1);
 border: 1px solid #DADADA;
    border-radius: 0.8rem;
    padding: 1rem;
}

.section-three .card h4{
    font-weight: bold;
    font-size: 24px;
    font-family: $secondary-font;
    margin-bottom: 1rem;
}

.section-three .card .readmore{
    color:$primary-green-color;
}


//======section three end

// .section-four{
//     margin-bottom: 10rem;
// }
.section-four .card{
    border: 2px solid $primary-golden-color;
    border-radius: 0.4rem;
    text-align: center;
    color:$primary-green-color;
    font-weight: 500;
    min-height: 6.7rem !important;
}

.section-four .card .serialNo{
    background: $primary-green-color;
    border-radius: 50%;
    width: 3rem;
    padding: 0.8rem 1rem 1rem 1rem;
    text-align: center;
    height: 3rem;
    color: #fff;
    margin: auto;
    margin-top: -3rem;
}

.section-four .card .text{
    font-size: 25px;
    font-weight: 500;
}
//======section four end

.section-price .card {
    color:$primary-green-color;
    border-radius: 0.8rem;
}
.section-price .head{
font-size: 25px;
}
.section-price .price{
    text-decoration: underline;
    font-size: 25px;
}
.section-price .card:hover, .section-price .card:focus {
    color:#fff;
    background: $primary-green-color;
    box-shadow:9px 7px 23px $box-shadow;
}

.section-price .card button{
 color:$primary-golden-color;
 background: #fff;
 border: 1px solid $primary-golden-color;
 border-radius: 0.5rem;
 padding: 0.5rem 2.5rem;
 font-weight: bold;

}

.section-price .card:hover button, .section-price .card:focus button{
 color:#fff;
 background: $primary-golden-color;
}
//======section price end
.section-help{
    background-color: $primary-green-color;
    margin-bottom: 5rem !important;
    padding-top: 5rem  !important;
    padding-bottom: 5rem  !important;
}

.section-help p{
    color: #DCDBDB;
    font-size: 23px;
    font-weight: 400;
}
.section-help .contactForm input{
    height: 3.5rem;
    border-radius: 10px;
   //@debug text-transform: capitalize;

}
.section-help .contactForm textarea{
    border-radius: 10px;
}

.section-help .contactForm .button{
    padding:1rem 3rem;
    width:12rem;
}
//==============section help end

.section-testimonial .card{
    border-radius: 10px;
    padding: 1rem;
    // margin: 2rem 1rem;
    background: #fff;
    // height: 100%;
    max-width: 425px;
    background: #fff;
    // height: 100%;
    max-width: 500px;
    margin: 0 auto;
    min-height: 100px;
}
.section-testimonial .card:hover{
    background: #253933;
    box-shadow: 9px 7px 23px #69696940;
    color: #fff;
}

.section-testimonial .card:hover .name{
    color: #fff;
}

.section-testimonial .card img{
    width: 82%;
    height: 40%;
    align-self: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.section-testimonial .card .name{
    color:$primary-green-color;
    font-size:18px;
}
.section-testimonial .card .designation{
    color:#898989;
    font-size:14px;
}

.section-testimonial .rec-arrow-right{
    background-color: $primary-golden-color;
    color: #fff;
    border-radius: 7px;
    margin-left: -3rem;
    z-index: 1;
}

.section-testimonial .rec-arrow-left{
    background-color: $primary-golden-color;
    color: #fff;
    border-radius: 7px;
    margin-right: -3rem;
    z-index: 1; 
}

.section-testimonial .rec-arrow-right:hover:enabled{
    background-color: $dark-golden;
}

.section-testimonial .rec-arrow-left:hover:enabled{
    background-color: $dark-golden;
}
.section-testimonial .rec-arrow-right:focus:enabled{
    background-color: $dark-golden;
}

.section-testimonial .rec-arrow-left:focus:enabled{
    background-color: $dark-golden;
}

.section-testimonial .rec-dot_active{
    background-color: $primary-golden-color;
    box-shadow:  0 0 1px 3px #d28d17
}

.section-testimonial .rec-dot:hover{
    background-color: $primary-golden-color;
    box-shadow:  0 0 1px 3px #d28d17
}

// .section-testimonial .iVQili:hover:enabled,.section-testimonial .iVQili:focus:enabled
// {
//     background-color: $dark-golden;
//     box-shadow: 0 0 2px 0 #333;
// }

.section-testimonial .rec-pagination{
    visibility: hidden;
}



//================section testimonial start
.section-blog{
    margin-bottom: 10rem;
}
.section-blog .carousel-inner{
    background-color: $primary-green-color;
    padding: 2rem 3rem;
    border-radius: 10px;
}

.section-blog .carousel-indicators{
    bottom: -4rem !important;
}
.section-blog .carousel-indicators button
{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #c4c4c4;
}

.section-blog .carousel-indicators button.active
{
    background-color: $primary-green-color;
}

.section-blog .carousel-control-prev {
    background-color: $primary-golden-color;
    height: 2rem;
    width: 2rem;
    text-align: center;
    top: 40%;
    left: -1rem;
    border-radius: 7px;
    opacity: 1;
    transition: none;
}

.section-blog .carousel-control-next {
    background-color: $primary-golden-color;
    height: 2rem;
    width: 2rem;
    text-align: center;
    top: 40%;
    right: -1rem;
    border-radius: 7px;
    opacity: 1;
    transition: none;
}

.section-blog .carousel-control-prev-icon, .section-blog .carousel-control-next-icon{
    width: 1.5rem;
    height: 1.5rem;
}

.section-blog .blog .heading{
    color: $primary-golden-color;
    font-weight: bold;
    font-size: 22px;
}

.section-blog a{
    color: $primary-golden-color;
    font-weight: bold;
}

.section-blog .blog p{
    color: #ffffff;
    padding: 2rem 0;
}

//=================section blog end
.section-footer{
    background-color: $primary-green-color;
    color:#fff;
}

.section-footer hr{
    margin-left:-3rem;
    margin-right: -2.4rem;
}

.section-footer .footer{
    padding-bottom: 1rem !important;
    font-size: 14px;
}
//============section-footer end

//===========hamburger page

.hamburger-page{
    background-color: $primary-green-color;
    color: #fff;
    //height: 100vh;
    position: absolute; 
    top: 0;
    left: 0;
    right:0; 
    bottom: 0;
}
.closeHamburger{
    color: #fff;
}

.hamburger-page .header button{
    color: #fff;
    background: $primary-green-color;
    font-size: 2rem;
    border: none;
}

.hamburger-page .footer{
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    // color: #fff;
    // background: $primary-green-color;
    padding: 7px 0;
}

.hamburger-page .main-section h1{
    color:$primary-golden-color;
    font-weight: bold;
    line-height: 4rem;
    margin-bottom: 3rem;
}

.hamburger-page  .main-section ul {
    list-style: none;
    font-weight: bold;
    line-height: 4rem;
    font-size: 25px;
}

.hamburger-page  .main-section li:hover {
    color:$primary-golden-color;
}

.removeFieldIcon{
    margin-top: 2.5rem;
    color: $primary-golden-color;
    background-color: white;
    border-radius: 1rem;
    height: 1.5rem;
    cursor: pointer;
    z-index:1;
    margin-left: 0.5rem;
}
.removeFieldIcon2{
    color: $primary-golden-color;
    background-color: white;
    border-radius: 1rem;
    height: 1.5rem;
    cursor: pointer;
    z-index:1;
}

//=====faq page=====

.faq-topics .title{
    font-size: 24px;
    color: #000000; 
}

.faq-section-one p{
    font-size: 3rem;
    font-weight: bold;
    color:$primary-green-color;
}

.faq-topics{
    color: gray;
    font-size: 22px;
    cursor: pointer;
}

.faq-section .accordion-item{
    margin-bottom: 2rem;
    border-radius: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 4px 9px $box-shadow;

}

.blogs section{
    padding: 0 3rem 0 3rem;
}

.blogs .blog-section .title{
    font-size: 21px;
}

.blogs .blog-section a{
    color: $primary-golden-color;
    text-decoration: none;
    font-weight: bold;
}

.blogs .blog-section .card{
    border-radius: 1rem;
}
.blogs .blog-section .card:hover{
    background-color: $primary-green-color;
    color: #fff;
}

.blogs .blog-section .card:hover .title{
    color: $primary-golden-color;
}

.blogs .blog-section .card{
    border-radius: 1rem;
}

.blog-search-section{
    padding: 4rem 5rem;
    background-color: $primary-green-color;
    color: #C8C8C8;
}

.blog-search-section .form-control{
    padding:0.375rem 0.75rem 0.375rem 3rem;
    height: 3rem;
    background-color: $primary-green-color;
    border-color: $grey-color;
}

.blog-search-section .form-control{
    padding:0.375rem 0.75rem 0.375rem 3rem;
    height: 3rem;
    background-color: $primary-green-color;
}

.blog-search-section .form-control:focus{
    background-color: $primary-green-color !important;
    color: #fff;
    border-color: $grey-color !important;
}

.blog-search-section .search-btn{
    padding: 14px;
    width: 9rem;
}

.faq-section .accordion-button:not(.collapsed){
    border-radius: 0.75rem;
    color: $primary-golden-color;
    background-color: #ffff;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 1.25rem 1.25rem 0rem 1.25rem; 
    box-shadow: none !important;
}

.faq-section .accordion-button{
    border-radius: 0.75rem;
    color: #5F5F5F;
    background-color: #ffff;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 1.25rem;
}

.faq-section .accordion-item:first-of-type .accordion-button, .faq-section  .accordion-item:last-of-type .accordion-button.collapsed{
    border-radius: 0.75rem;
}

.faq-section .accordion-button:not(.collapsed)::after {
    -webkit-filter: invert(1) contrast(75%);
    transform: rotate(0deg);
}
.faq-section .accordion-button::after {
    transform: rotate(180deg);
}

.faq-section .accordion-button:focus{
    box-shadow: none;
}

//=======blog ======
// .blog-section-one{
//     padding-top:10rem;
// }
.blog-section-one p{
    font-size: 2.5rem;
    font-weight: bold;
    color:$primary-green-color;
}

//===============

.landing-page{
    //margin-top: 65px;
    counter-reset: section;
}

.landing-page section{
    padding: 3rem 2rem;
    margin-bottom: 1rem;
    min-height: 485px;
}

.landing-page .carousel{
    display: flex;
    flex-direction: column;
}

.landing-page .carousel-item{
    height:485px
}

.landing-page .carousel-indicators {
    order:2;
    bottom: -3.8rem;
    //position: fixed;
    //bottom: 2.6rem;
    width: 60%;
}

.landing-page .carousel-indicators [data-bs-target] {
    width: 9.5rem !important;
    border-radius: 5px !important;
    text-indent: -8px  !important;
    box-sizing: inherit !important;
    margin:0 12rem 0 0 ; 
}

.landing-page .carousel-indicators>:first-child {
    display: none !important;
}

.landing-page .carousel-indicators [data-bs-target]:not(:first-child)::before {
    counter-increment: section !important;
    content: counter(section, decimal-leading-zero) !important;
    color: $primary-green-color;
    font-weight: bolder;
    position: absolute;
    top: -0.6rem;
}


.landing-page .carousel-indicators [data-bs-target]:not(:last-child)::after {
    content: "";
    background-color: $primary-green-color;
    position: absolute;
    top: -0.1rem;
    height: 0.3rem;
    width: 10rem;
    margin-left: 21px;
    border-radius: 5px;
}

.landing-footer .scroll_side_gif{
    height: 3rem !important;
    cursor: pointer;
}

.landing-footer {
    bottom: 1.5rem,
}

.landing-page .heading{
    color: $primary-green-color;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: normal;
}

.landing-page p{
    font-size: 19px;
}

.landing-footer .page-number{
    cursor: pointer;
}
.landing-footer .page-number.active{
    color: $primary-green-color;
    font-weight: bolder;
}
.landing-footer .page-number.visited::after{
   background-color: $primary-green-color;
    content: "";
    position: absolute;
    height: 5px;
    width: 9.5rem;
    border-radius: 5px;
    top: 1.5rem;
    margin: 0 1rem;
}

.landing-page .diveIn{
    color:$primary-green-color;
    cursor: pointer;

}

.landing-page .diveInIcon{
    background-color: $primary-green-color;
    color: white;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    padding: 2rem;
    cursor: pointer;
}

.landing-page-gif{
    border-radius: 0.5rem;
    width: 100%;
    box-shadow: 0 0 3px 7px $box-shadow;
}

.skipToWebLink{
    cursor: pointer;
    position: absolute;
    right: 0; bottom: 0
}

.diveInSection{
    cursor: pointer;
}

.top-bar.navbar-shadow{
    box-shadow: 0px 4px 9px $box-shadow;
}

.mutual-fund-box{
    word-break: break-word;
    box-shadow: 5px 2px 17px $box-shadow;
    border-radius: 8px;
    padding: 1rem;
    height: 17rem;
    margin-bottom: 2rem;
    border: 1px solid transparent;
}
.mutual-fund-box p{
    font-size: 14px;
}

.stock-etf-box{
    word-break: break-word;
    box-shadow: 5px 2px 17px $box-shadow;
    border-radius: 8px;
    padding: 1rem;
    max-height: 15rem;
    margin-bottom: 2rem;
    border: 1px solid transparent;
}
.stock-etf-box p{
    font-size: 14px;
}

.tool-tip-box{
    word-break: break-word;
    box-shadow: 5px 2px 17px $box-shadow;
    border-radius: 8px;
    padding: 1rem;
    height: 12rem;
    margin-bottom: 2rem;
    border: 1px solid transparent;
    margin-left:1rem;
}
.tool-tip-box p{
    font-size: 14px;
}


.mutual-fund-box.hidden {
    visibility: hidden;
}

.messageBox{
    font-size: 14px;
    word-break: break-word;
    box-shadow: 5px 2px 17px $box-shadow;
    border-radius: 8px;
    padding: 0.5rem;
    border: 1px solid transparent;
    margin-left:1rem;  
    color:$primary-green-color;
    text-align: center;
}
//=======================react dropdown =====//
.css-1d8n9bt {
    -webkit-box-align: center !important;
    align-items: center !important;
    display: grid !important;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 0 8px !important;  //change here
    position: relative !important;
    overflow: hidden !important;
    box-sizing: border-box !important;
}

.css-1okebmr-indicatorSeparator {
    align-self: stretch;
    background-color: rgb(204, 204, 204);
    margin-bottom: 1px !important;
    margin-top: 0 !important; 
    width: 1px !important;
    box-sizing: border-box !important;
}
.css-ackcql {
    margin: 0 !important; 
    padding-bottom: 0 !important;
    padding-top: 0px !important;
    visibility: visible !important;
    color: rgb(51, 51, 51) !important;
    flex: 1 1 auto !important;
    display: inline-grid !important;
    grid-area: 2 / 1 / 1 / 1;
    grid-template-columns: 0px min-content !important;
    box-sizing: border-box !important;
}

.AssetIconTooltip{
    width:10rem;
    z-index: 99999999 !important;
}

//====add your wish page=====
.addwish-section-one{
    //display: flex;
    padding: 1rem;
    margin:1rem 0;
    border: 1px solid #d7d7d7;
    border-radius: 0.5rem;
}

.addwish-section-one p{
    margin-top:1rem;
    font-size: 0.8rem;
    color: $dark-grey-color;
    padding-right: 0.8rem;
}

//=========custom scroll============

.custom-scroll{
    scrollbar-width: thin !important;
    scrollbar-color: #1f5a30 transparent !important;
}

.custom-scroll::-webkit-scrollbar{
    width: 5px;
}
.custom-scroll::-webkit-scrollbar-thumb{
    background-color: #1f5a30;
    border-radius: 100vw
}
.custom-scroll::-webkit-scrollbar-track{
    background-color: transparent;
}
//=========custom scroll end===========

.examplesDiv{
    border-left: 1px solid #d7d7d7;
    padding-left: 1rem;
}

.examplesDiv ul{
    padding-left: 1.5rem !important;
    max-height: 13rem;
    overflow-y: scroll;
    margin-top:1rem;
}
.examplesDiv  li:not(:last-child) { 
   margin-bottom: 1rem;  
}

.examplesDiv li div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.8rem;
}

.small-button{
    padding: 12px 24px 11px;
    border: none;
    background: $primary-golden-color;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    z-index: 1;
    outline: none !important;
}

.addedWishesSection{
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 1rem;
}

.link{
    color: $primary-golden-color;
    cursor: pointer;
    text-decoration: none;
    align-self: center;
    //margin-right: 2rem;
    z-index: 2;
}

.link:hover{
    color: $primary-golden-color;
    font-weight: bold;
}
.wishTable{
    font-size: 16px;
}

.viewdraft-pdfviewer{
    border: 1px solid rgba(0, 0, 0, 0.3);
    height: 750px;
}

//==================support page================

.support section{
    padding: 0 3rem;
}

.will_types{
    background-color: $primary-green-color;
    color:#fff;
}

.will_types .accordion-button:focus {
    border-color: none;
    box-shadow: none;
}

.will_types .accordion-item:first-of-type, .accordion-item:first-of-type .accordion-button {
    border-radius: 0%;
}

.will_types .accordion-item{
    background-color: $primary-green-color;
    border: none;
}
.will_types .accordion-button::after{
    display: none;
}
.will_types .accordion-button:not(.collapsed)::after{
    display: none;
}

.will_types .accordion-button{
    color:#fff;
    background-color:$primary-green-color;
    background:
    linear-gradient(#fff,#fff) bottom center/calc(100% - 2.5rem) 1px;
    background-repeat: no-repeat;
    padding-bottom: 0.5rem;
}

.will_types .accordion-button:not(.collapsed){
    color:$primary-golden-color;
    background-color:$primary-green-color;
    box-shadow: none;
}

.support .one a{
    color: #000;
}
.support .one a:hover{
    font-weight: bold;
}

.support .paragraph{
  font-size: 2.5rem;
}

.terminlogoy-search-icon {
    position: absolute;
    padding: 12px 14px 11px 2px;
    min-width: 50px;
    text-align: center;
    height: 1.5rem;
    color:$grey-color;
}

.terminology .form-control{
    padding:0.375rem 0.75rem 0.375rem 3rem;
    height: 3rem;
}

.terminology .search-btn{
    padding: 14px;
    width: 9rem;
}

.terminology .card{
    padding: 1rem 1rem 0rem 1rem;
    border-radius: 0.8rem;
    min-height: 13rem ;
    font-size: 16px;
    cursor: pointer;
}

.terminology .card .title{
    font-size: 18px;
}

.terminology .card:hover,.terminology .card:focus{
    background: $primary-green-color;
    box-shadow:9px 7px 23px $box-shadow;
    color: #fff;
}

.terminology .card:hover .title{
    color: #F2B84B;
}

.blog-detail .intro{
    background-color: $primary-green-color;
    color: #fff;
}
.blog-detail .intro .heading{
    color: $primary-golden-color;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 0.5rem;
}

.blog-detail .content ul{
    list-style: none;
    padding-left: 0;
    line-height: 2.5rem;
    color: $dark-grey-color;
}

.blog-detail .content .heading{
    font-weight: bold;
    font-size: 20px;
    padding-left: 0.5rem;
    border-left: 4px solid $primary-golden-color;
    border-radius: 2px;
}

.password-notes {
    .btn-primary {
        font-size: 1.4rem;
        margin: 0.3rem 0;
    }
}

.radio-group {
    display: block;
    .btn-primary {
        background-color: transparent;
        font-size: 0.8rem;
        border: 0;
        border-radius: 0;
        padding: 0;
        text-align: left;
        color: $grey-color;
        padding-left: 1.5rem;
        font-weight: 400;
        flex: 0;
        //margin-right: 2rem;
        input {
            display: none;
        }
        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 1rem;
            height: 1rem;
            background-color: $input-bg-color;
            border-radius: 0.2rem;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        &.active, &:hover, &:active, &:focus {
            background-color: transparent !important;
            color: $grey-color !important;
            &:before {
                background-color: $primary-golden-color;
            }
        }
    }
    &.radio-group-1 {
        .btn-primary {
            &:before {
                border-radius: 100%;
            }
            &:after {
                content: "";
                position: absolute;
                display: block;
                width: 0.8rem;
                height: 0.8rem;
                background-color: $input-bg-color;
                border-radius: 100%;
                left: 0.5rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    label {
        font-size: 1.2rem;
        color: $grey-color;
        padding-left: 2.5rem;
        font-weight: 400;
        margin-right: 2rem;
        position: relative;
        margin-bottom: 0;
        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 1.8rem;
            height: 1.8rem;
            background-color: $input-bg-color;
            border-radius: 100%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        input {
            display: none;
            &:checked {
                ~ {
                    label {
                        &:before {
                            border: 0.5rem solid $primary-golden-color;
                        }
                    }
                }
            }
        }
    }
}

.toggleEye{
    position: absolute;
    color:$dark-grey-color;
    right: 0;
    top:0.5rem;
    cursor: pointer;
}


.passwordSetting .toggleEye{
    color:$dark-grey-color;
    right: 1.1rem;
    top:2.8rem;
    cursor: pointer;
}


.form-control.is-invalid.password{
    background-image: none !important;
}

//============passRequirements
.passRequirements ul{
    list-style: none;
    padding-left: 0;
    li{
        font-size: 0.8rem;
        text-align: left;
        color: #565252;
        font-weight: 400;
        display: flex;
        .icon{
            height: 1.2rem;
            color: $grey-color;
        }
        .red{
            color: #dd2d0ee3;
        }
        .green{
            color: #46c746c7;
        }
    }
}

.gmail-icon, .whatsapp-icon, .download-icon{
    cursor: pointer;
}

.will-style{
    display: flex !important;
    flex-direction: column;
}

.bottom-space{
    margin-bottom: 15px;
}

.modal-backdrop.show{
    z-index: 9999;
}

.is-active{
    color: #fff !important;
    background: #253933;
    box-shadow: 9px 7px 23px #69696940;
}

.initial-will-type{
    text-align: center;
    margin-top: 30px;
}

.will-type-proceed{
    text-align: center;
    margin-top: 30px;
}

.upgrade-Will-button{
    margin-bottom: 10px;
}

.executor-style{
    text-align: center;
}
