
//1200, 991, 767, 576, 480, 320,  

@media only screen and (min-width: 992px){
  .makeWillBtnDiv{
    display: flex;
  } 
  .md-div{
    display: none;
  }
}

@media only screen and (max-width: 991px){

  .lg-div{
    display: none;
  }
  
  .makeWillBtnDiv{
    display: none;
  }

  .page-sub-heading{
    font-size: 17px;
  }

  .acc-page-container{
    margin-top: 0.5rem !important;
  }

  .acc-page-menu{
    margin-bottom: 0;
  }

  .acc-page-menu-divider {
	  border-bottom: 2px solid #E6E6E6;
    border-right: 0;
    display: flex;
    overflow-x: scroll;
  }

  .acc-page-heading{
    margin-top: 1rem;
  }

  .main-layout-cont{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .register-img{
    width: 95%;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 767.99px) {
  body p{
    text-align: justify !important;
  }

  .headerLogo{
    width: 95%;
  }
}

@media only screen and (max-width: 767px){
  
  .page-sub-heading{
    font-size: 16px;
  }
  body p{
    text-align: justify !important;
    font-size: 16px;
  }

  .headerLogo{
    width: 80%;
  }

  .landing-footer{
    position: static;
  }
 
  .skipToWebLink{
    left: 0;
    margin-top: .5rem;
    position:relative;
    text-align: center;
  }
  .top-bar {
    padding: 0px 0px;
    //width: 92%;
    //left: 4%;
    height: 67px;
  }

  .landing-page .carousel-indicators {
    width: 96%;
    bottom: -1.5rem;
  }

  .landing-page .carousel-indicators [data-bs-target] {
    text-indent: -3rem  !important;
    margin:0; 
  }

  .landing-page .carousel-indicators [data-bs-target]:not(:last-child)::after {
    width: 5.5rem;
    margin-left: -0.5rem;
    border-radius: 5px;
  }
  .landing-page .diveInIcon{
    height: 2rem;
    width: 2rem;
  }

  .why-trust-head{
    text-align: center;
    margin-bottom: 1rem;
  }

  .home-page section {
    padding: 0 2rem 0 2rem;
  }


  .section-one .rightSection .serialNo {
    width: 3.5rem;
    padding: 0.9rem 1.3rem 1.2rem 1.3rem;
    height: 3rem;
  }

  .section-three .nav-item {
    padding: 0.5rem;
  }

  .section-four .card{
    border: none;
    text-align: left;
  }

  .section-four .card .serialNo{
      margin-top: 0;
      margin-bottom: 0;
  }
  .section-four .card .card-body{
    display: flex;
    text-align: left;
    align-self: flex-start;
    padding: 0;
  }

  .section-four .card .text{
    margin-top: 0.3rem;
    font-size: 20px;
    padding-left: 1rem;
  }

  .section-four .mycard:not(:last-child){
    .serialNo{
      &::after{
        content: "";
        background-color: #E49F2A;
        position: absolute;
        top: 3.2rem;
        height: 3.3rem;
        width: 0.3rem;
        margin-left: -6px;
        border-radius: 5px;
      }
    }
  }
  .section-four{
    margin-bottom: 2rem;
  }
  .section-help .contactForm .button {
    padding: 1.2rem;
    width: 8rem;
  }

  .landing-page .carousel-item{
    height:740px
  }

  //=========
  .burgerPageContent{
    flex-direction: column-reverse;
    padding: 0 2rem;
    font-size: 25px;
    text-align: justify;
  }
  .burgerPageContent h1{
    line-height: 2.3rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    //font-size: 25px;
  }

  .burgerPageContent .sectionLeft ul{
    line-height: 2rem;
    font-size: 20px;
    padding-left: 0;
  }
  
  .main-layout{
    flex-direction: column;
  }

  .user-drop-down{
    margin: 0;
  }

  .login-form input{
    margin-top: 1rem;
  }

  .section-three{
    padding:0rem 0rem 2rem 0rem ;
    --path: 0% 0%, calc(211%) 17%, 100% 100%, 0% 92%;
    clip-path: polygon(var(--path));
  }

  .section-three .card h4, .section-three .card .h4 {
    font-size: 20px;
    margin-bottom: 0.7rem;
  }

  .rowDiv{
    --bs-gutter-x: 0;
  }
  
  .left-menu-container {
    margin-top: 0.5rem;
  }

  .left-menu-container ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    //--bs-gutter-x: 0;
  }

  .left-menu-container ul li {
    width: 7rem;
    padding: 10px 2px;  
    //line-height:175%;
    margin-bottom:0 ;
    height: 0%;
    font-size: 12px;
  }

  .left-menu-container li a {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
  }

  .left-menu-icon {
    border-radius: 50%;
    background-color: rgba(163.62499594688416, 163.62499594688416, 163.62499594688416, 1);
    margin: auto;
    position: initial;
    padding: 5px 0;
    margin-bottom: 5px;
  }

  .fullVh{
    height: 0vh;
  }

  .left-menu-text {
    left: 0%;
    padding-left: 0;
    padding-right: 0;
    word-break: inherit;
    text-align: center !important;
    word-spacing: normal !important;
  }
  

  .left-menu-container ul li::after {
    width: 11vw;
    height: 2px;
    top: 3vw;
    left: 10vw;
    border-radius: 4px;
  }

  // .left-menu-container ul li::after {
  //   width: 71%;
  //   top: 22px;
  //   left: 4.5rem;
  // }

  .main-layout .layout__headings{
    margin-top: 1.5rem;
    font-size: 18px;
  }

  .btn-2{
    height: 2rem;
    font-size: 0.8rem;
    display: flex;
    padding: 0.4rem
  }

  .btn-2 .shareIcon{
    padding-top: 0.1rem
  }

  .addAssetBtn {
    height: 2rem;
    width: 5rem;
    font-size: 0.7rem;
    padding: 0.4rem 0.2rem;
  }

  footer {
    padding: 0.5rem;
  }

  footer .footer-contact span{
    font-size: 0.8rem;
    font-weight: 500;
  }

  .footer-note{
    font-size: 0.8rem;
    font-weight: 500;
  }

  .button {
    width: 9rem;
    padding: 10px;
    font-size: 14px;
    line-height: 1;
  }

  [data-palmerhq-radio]{
    font-size: 15px;
  }

  .modalCloseIcon{
    height: 2rem;
  }

  .layout3section {
    margin-top: 3.5rem;
  }

}

//@include media-breakpoint-between(md,sm) {

@media only screen and (max-width: 576px) {

  .layout3section {
    margin-top: 2.5rem;
  }

.blog-search-section ul{
  list-style: none;
}
.blogs section {
  padding: 0 1rem;
}

.blog-section-one p {
  font-size: 20px;
}

.blog-search-section .search-btn {
  width: 5rem;
}

.blog-search-section .form-control{
  height: 2.5rem;
}

  .faq-section-one p{
    font-size: 2rem;
  }

  .faq-topics .title{
    font-size: 18px;
    color: #000000; 
  }
  .faq-topics{
    font-size: 16px;
    overflow-x: scroll;
  }

  .support .faq h4 {
    font-size: 22px;
  }

  .faq-section .accordion-button:not(.collapsed){
    font-size: 1rem;
  }
  .support .faq-section .accordion-button{
    font-size: 1rem;
  }
  .support section{
    padding: 1rem;
  }

  .terminology .form-control {
    height: 2.5rem;
  }

  .terminlogoy-search-icon{
    height: 1.2rem;
    padding: 0.7rem 0rem 0.7rem 0;
  }
  .terminology .search-btn {
    padding: 13px;
    width: 5rem;
  }

  .support .paragraph{
    font-size: 1rem;
  }

  .assetSummaryTable tbody {
    line-height: 1.3rem;
  }
  .file-icon {
    margin: auto;
  }

  .editIcon {
    width: 1rem;
    height: 1rem;
    margin: auto;
  }
  .acc-page-heading{
    font-size: 18px;
  }

  .dropbox-container .tagline {
    font-size: 16px;
  }

  .dropbox-container{
    padding: 8rem 1rem !important;
  }

  .uploadFileList img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .uploadFileList span {
    font-size: 15px;
  }

  .uploadFileList .progress {
    margin-top: 0rem;
    margin-bottom: 0.2rem;
  }

  .uploadFileList{
    font-size: 15px;
  }

  .progress {
    height: 0.5rem;
  }

  .download-button{
    min-width: 7rem;
    font-size: 17px;

  }
  .executorPopup .modal-body{
    font-size: 18px;
  }

  .add-nominee-btn{
    margin-top: 0rem;
    max-width:6rem ;
    padding: 5px;
    font-size: 15px;
    width: 4rem;
  }

  .messageBox{
    margin-left:0;  
  }

  // .rpv-core__page-layer{
  //   width: 100% !important;
  // }
  
  .page-sub-heading{
    font-size: 15px;
  }
  .main-layout-cont form input {
    height: 40px;
  }

  .form-control {
    font-size: 15px !important;
    //padding: 0 10px;
  }

  .tool-tip-box{
    height: 15rem;
    margin-left: 0;
  }

  .mutual-fund-box{
    height: 19rem;
    width:95%;
  }
  .stock-etf-box{
    height: 19rem;
    width:95%;
  }

  // .registerPage {
  //   margin-top: -4rem;
  // }

  .leftSection p{
    font-size: 18px;
  }
  .landing-page .diveInIcon{
    height: 1.5rem;
    width: 1.5rem;
  }

  .landing-page p {
    font-size: 16px;
  }
  body p{
    font-size: 18px;
  }

  .landing-page{
    text-align: center;
  }

  .vidsection{
    margin-top: 1.5rem;
  }

  .landingFooter-right{
      height: 1.5rem;
  }

  .bannerImg {
    width: 60%;
    height: 80%;
    //padding-top: 1rem;
  }

  .landing-footer .scroll_side_gif {
    height: 2.4rem !important;
  }

  .landing-page section{
    padding: 1rem;
  }

  .landing-page .carousel-indicators {
    width: 96%;
    bottom: -1.5rem;
  }

  .landing-page .carousel-indicators [data-bs-target] {
    text-indent: -3rem  !important;
  }

  .section-one .leftSection {
    padding-top: 2rem;
  }

  .why-trust-head {
    line-height: 2rem;
  }

  .home-page .section-two .desktop {
    display: none;
  }
  .home-page .section-two .slider {
    display: block !important;
  }
  .home-page .section-three .desktop {
    display: none;
  }
  .home-page .section-three .slider {
    display: block !important;
    padding-bottom: 3rem;
  }

  .section-price .head {
    font-size: 20px;
  }
  .section-price li {
    font-size: 14px;
  }

  .section-price .card {
    margin-bottom: 1rem;
  }

  .section-help{
    margin-bottom: 3rem !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem  !important;
  }
  .section-help .why-trust-head{
    display: none;
  } 
  .section-help .head2{
    display: block !important;
  }

  .section-help p {
    font-size: 18px;
  }

  .section-help .contactForm input {
    height: 2.5rem;
    margin-bottom: 0.8rem;
  }

  .section-help .contactForm textarea {
    margin-bottom: 0.8rem;
  }

  .section-testimonial .rec-arrow-left{
    display: none;
  }
  .section-testimonial .rec-arrow-right{
    display: none;
  }

  .section-testimonial .rec-pagination{
    visibility: visible;
  }
  .section-testimonial .card{
    margin: 0;
  }

  .section-testimonial .card img {
    width: 40%;
    margin-top: 0rem;
  }

  .home-page .section-testimonial .testimonial
  {
    display: none;
  }

  .home-page .section-testimonial .mobile-view
  {
    display: block !important;
  }

  .section-blog .carousel-control-prev{
    display: none;
  }
  .section-blog .carousel-control-next{
    display: none;
  }

  .section-blog .carousel-inner {
    padding: 1rem;
  }
  .section-blog .blog .heading {
    font-size: 20px;
  }
  .section-blog .blog p {
    padding:0;
    padding-top: 1rem;
  }

  .smallFooter{
    font-size: 14px;
    padding: 1rem;
    display: block !important;
  }
  .desktopFooter{
    display: none;
  }

  .smallFooter .leftSection{
    padding: 0.5rem;
  }

  .section-three .section-heading {
    padding-top: 3.5rem;
  }

  .landing-page .carousel-item{
    //height:545px
    height:28rem;
  } 

  

  .burgerPageContent h1{
    line-height: 2.3rem !important;
    margin-bottom: 1rem !important;
    margin-top: 1rem;
    font-size: 25px;
  }

  .burgerPageContent .sectionLeft{
    padding-top: 2rem;
  }

  .burgerPageContent .sectionLeft ul{
    font-size: 19px;
  }

  .hamburger-page .footer {
    font-size: 14px;
    padding: 7px 1rem;
  }

  .btnRow{
    text-align: center;
  }

  .register-checkbox{
    width: 90%;
  }

  .subheader { 
    padding: 1rem 0rem;
  }

  .subheader .profile-pic{
    width: 2rem;
    height: 2rem;
  }
  .subheader .username{
    font-size: 15px;
    margin-top: 7px;
    margin-left: 0.5rem !important;
  }

  .logout-button {
    width: 7rem;
    height: 2rem;
    margin-top: 0rem;
  }

  // .subheader .leftSection{
  //   width: auto;
  // }

  // .subheader .rightSection{
  //   width: auto;
  // }

  .acc-page-menu{
    font-size: 14px;
    padding: 0.5rem;
  }

  .acc-page-menu:hover {
    font-size: 14px;
    padding: 0.5rem;
  }

  .acc-page-menu:focus {
    font-size: 14px;
    padding: 0.5rem;
  }
  .acc-page-menu.active{
    font-size: 14px;
    padding: 0.5rem;
  }

  .willPageDropDown .dropdown-menu{
    min-width: 6rem !important;
    width: 7rem !important;
    font-size: 0.7rem;
  }

  .willPageDropDown .dropdown-toggle{
    min-width: 6rem !important;
    font-size: 0.8rem;
    padding: 0.25rem 0.25rem;
  }

  footer {
    position: initial;
  }

  .left-menu-container ul li::after {
    top: 4vw;
    left: 11vw;
  }

  .examplesDiv{
    border-top: 1px solid #d7d7d7;
    border-left: none;
    padding-left: 0;
    padding-top: 1rem;
  }

  .examplesDiv ul {
    padding-left: 1rem !important;
  }

  .payPlanContainer {
    display: initial;
  }

  .user_name::after {
    right: 1rem;
  }


  .drop-down-box li {
    padding: 8px;
    padding-bottom: 0;
    font-size: 14px;
  }

  .drop-down-box li a {
    line-height: 1;
  }

  .drop-down-box {
    width: 98px;
    padding: 8px 0;
    left: -11px;
    margin-top: 7px;
  }

  .drop-down-box::before {
    top: -15px;
    left: 35px;
  }  
}


@media only screen and (max-width: 400px) {
  .landingFooter-right{
      height: 4.5rem;
  }
  .skipandgobtn{
    width: 11rem !important;
    max-width: 11rem !important;
  }

  body {
    font-size: 15px;
  }
  body p {
    font-size: 15px;
  }

  .pay-success-ol{
    line-height: 27px;
    font-size: 15px;
  } 

  .landing-page .heading{
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .landing-page .carousel-indicators [data-bs-target] {
    text-indent: -1rem  !important;
  }
  
  .landing-page .carousel-indicators [data-bs-target]:not(:last-child)::after {
    width: 2.5rem;
    margin-left: 0.3rem;
  }

  .landing-page .carousel-indicators{
    bottom: -3rem;
    width: 80%;
  }

  .skipToWebLink {
    bottom: 0.5rem;
  }
  .section-one .rightSection .serialNo {
    width: 3rem;
    padding: 0.6rem 1rem 1rem 1rem;
    height: 2.5rem;
    margin-left: -2.5rem;
  }

  .section-one .rightSection{
    padding-right: 0.8rem ;
    padding-left: 0.8rem ;
  }

  .home-page section {
    padding: 0rem 1rem 1rem 1rem;
    margin-bottom: 2rem;
  }

  .border-line {
    width: 4.5rem;
    padding: 4px;
    margin-left: -1rem;
    margin-bottom: 1rem;
  }

  //================

  .section-two .carousel-inner{
    border-radius: 10px;
  }
  .section-two .card-body {
    padding-bottom: 0;
  }

  .section-two .serialNo {
    font-size: 1rem;
    padding-right: 0;
    line-height: 1.3rem;
  }
  .section-two .carousel-indicators{
    bottom: -3rem;
  }
  .section-two .carousel-indicators button
  {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #c4c4c4;
  }

  .section-two .carousel-indicators button.active
  {
    background-color: #E49F2A;
  }

  //========
  .home-page .section-four{
    margin-bottom: 0;
   }
  .section-three .carousel-indicators{
    bottom: -3rem;
  }
  .section-three .carousel-indicators button
  {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #c4c4c4;
  }

  .section-three .carousel-indicators button.active
  {
    background-color: #E49F2A;
  }

  .section-help .contactForm .button {
    padding: 0.7rem;
    width: 7rem;
  }

  .smallFooter{
    font-size: 12px;
  }

  .landing-page .carousel-item{
    height:520px
  }

  .login-title{
    font-size: 20px;
  }

  .logout-button {
    width: 6rem;
    height: 2rem;
  }

  .pointer-icon{
    height: 20px;
    width: 20px;
    top:7px;
  }

  .pointer-icon.start {
    left: 0;
  }

  .pointer-text.start {
    left: 0;
  }

  .pointer-icon.end {
    right:0; 
    top: 7px;
  }

  .pointer-text{
    font-size: 10px;
  }

  .notification-page-row {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 1rem
  };

  .slider:before{
    height: 22px !important;
    width: 22px !important;
    bottom: -3px !important;
  }

  .switch {
    height: 1rem !important;
    width: 2.5rem !important;
    margin-top: 0.8rem;
  }

  input:checked + .slider:before {
    transform: translateX(24px) !important;
  }

  .left-menu-container ul li::after {
    width: 14vw;
    top: 6.5vw;
    left: 12vw;
  }

  .button {
    max-width: 7rem;
    min-width: 3rem
  }

  .layout3section {
    margin-top: 1.5rem;
  }

}

@media only screen and (max-width: 340px) {
  
  .user_name::after {
    right: 0rem;
  }
  .left-menu-container ul li::after {
    top: 7vw;
  }
}


@media only screen and (max-width: 320px) {

  .removeFieldIcon{
    margin-left: 0.2rem;
    margin-top: 2rem;
  }
  body {
    font-size: 14px;
  }

  .border-line {
    margin-left: -0.3rem;
  }

  .section-three{
    padding:0rem 0rem 2rem 0rem ;
  }

  .section-three .card {
    background: #fff5e4;
    border: 0;
    border-radius: 0;
    padding: 0;
  }

  .home-page .section-four{
    margin-bottom: 0;
   }

  .landing-page .carousel-indicators [data-bs-target]:not(:last-child)::after {
    width: 2rem;
  }

  .smallFooter{
    font-size: 10px;
  }
  .landing-page .carousel-item{
    height:420px
  }

  .left-menu-container ul li {
    width: 3rem;
    padding: 4px;
    margin-bottom: 0;
    height: 0%;
  }
  
  .left-menu-container ul li::after {
    width: 38%;
    top: 16px;
    left: 2.5rem;
  }
}